$cart-next-step-color: $gray-400;
$cart-next-hover-color: $gray-800;
$cart-active-color: $ocean;
.cart-steps {
  padding-bottom: 40px;
  border-bottom: 1px solid $gray-100;
  ul {
    border: 1px solid $gray-300;
    padding: 20px;
  }
  li {
    display: inline-flex;
    width: 30%;
    margin-right: 3%;
    border-right: 1px solid $gray-200;
    &:last-child {
      border-right: 0;
    }
    > a,
    > span,
    > strong {
      display: inline-flex;
      width: 100%;
      align-items: center;
      color: $cart-active-color;
    }
    > a {
      color: $cart-active-color;
      font-weight: bold;
      &:hover {
        border-color: $cart-next-hover-color;
        color: $cart-next-hover-color;
        .num {
          color: $cart-next-hover-color;
          border-color: $cart-next-hover-color;
        }
      }
      .num {
        color: $cart-active-color;
        border-color: $cart-active-color;
      }
    }
    > span {
      color: $cart-next-step-color;
      cursor: default;
    }
    > strong {
      cursor: default;
      .num {
        border-color: $cart-active-color;
        background-color: $cart-active-color;
        color: white;
      }
    }
    .num {
      width: 60px;
      height: 60px;
      margin-right: 10px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      vertical-align: middle;
      border-radius: 50%;
      border: 1px solid $cart-next-step-color;
      font-weight: bold;
      font-size: 25px;
    }
  }

  @include media-breakpoint-down(md) {
    li {
      display: inline-flex;
      width: 100%;
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid $gray-200;
      border-left: 0;
      border-right: 0;
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0;
      }
    }
  }
}
