.iconbox {
  position: relative;
  border: 1px solid #ddd;
  padding: 50px 20px 10px;
  text-align: center;
  margin-top: 40px;
  height: 100%;
  .icon {
    position: absolute;
    top: -25px;
    left: 50%;
    margin-left: -25px;
    width: 50px;
    height: 50px;
    border: 1px solid $tiger;
    border-radius: 50%;
    background: $tiger;
    color: #fff;
    font-family: 'FontAwesome';
    font-size: 20px;
    line-height: 50px;
    text-align: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
