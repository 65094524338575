//
// navbar
//
//

.navbar {
  min-height: $header-height;

  @include media-breakpoint-down(md) {
    min-height: auto;
    padding: ($grid-gutter-width / 2);

    .navbar-toggler {
      border: 0;
    }
  }
}

.navbar-brand-box {
  display: flex;
  gap: 20px;
  align-items: center;

  @include media-breakpoint-down(md) {
    gap: 15px;
  }
}

.navbar-brand {
  margin: 0;
  max-width: 150px;
  display: flex;
  align-items: center;

  & + .navbar-brand {
    position: relative;
    display: none;

    &:before {
      content: '';
      top: 0;
      left: -16px;
      position: absolute;
      width: 1pt;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.6);
    }
  }

  @include media-breakpoint-down(md) {
    max-width: 100px;

    & + .navbar-brand {
      display: flex;
      margin-top: 0;
      padding: 0;

      .nav-link {
        padding: 0;
      }

      &:before {
        left: -12px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .navbar-collapse {
    //flex-basis: 40% !important;
  }
}

.navbar-nav {
  @include media-breakpoint-up(lg) {
    .nav-item + .nav-item {
      margin-left: 20px;
    }
  }
  
  @include media-breakpoint-up(xl) {
    .nav-item + .nav-item {
      margin-left: $grid-gutter-width;
    }
  }

  .nav-link {
    @include transition;
    padding: rem(5);
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-transform: uppercase;
    font-size: $font-size-sm;

    strong {
      font-weight: 700;
    }
  }

  @include media-breakpoint-down(md) {
    margin-top: $grid-gutter-width;
  }
}
